import Link from "@mui/material/Link";
import {OWN_URL} from "../../../../ApiConstant";
import Typography from "@mui/material/Typography";
import * as React from "react";

export const Copyright = (props) => {
	return (
			<Typography variant="body2" color="text.secondary" align="center" {...props}>
				{'Copyright © '}
				<Link color="inherit" href={OWN_URL}>
					BecomePolyglot.org
				</Link>{' '}
				{new Date().getFullYear()}
				{'.'}
			</Typography>
	)
}
