// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PrimaryButton_buttonPrimary__AVcRs {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: .375rem;
  border-width: 1px;
  border-color: transparent;
  text-align: center;
  font-weight: 500;
  text-decoration-line: none;

  padding: .5rem 1rem;
  font-size: 1rem;
  line-height: 1.5rem;

  background-color: var(--main-accent-color);
  color: var(--main-accent-opposite-color);
}

.PrimaryButton_buttonPrimary__AVcRs:hover {
  background-color: var(--main-accent-hover-color);

}

.PrimaryButton_fullWidth__Zp2je{
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ui/button/primary/PrimaryButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;;EAEvB,sBAAsB;EACtB,iBAAiB;EACjB,yBAAyB;EACzB,kBAAkB;EAClB,gBAAgB;EAChB,0BAA0B;;EAE1B,mBAAmB;EACnB,eAAe;EACf,mBAAmB;;EAEnB,0CAA0C;EAC1C,wCAAwC;AAC1C;;AAEA;EACE,gDAAgD;;AAElD;;AAEA;EACE,WAAW;AACb","sourcesContent":[".buttonPrimary {\n  position: relative;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n\n  border-radius: .375rem;\n  border-width: 1px;\n  border-color: transparent;\n  text-align: center;\n  font-weight: 500;\n  text-decoration-line: none;\n\n  padding: .5rem 1rem;\n  font-size: 1rem;\n  line-height: 1.5rem;\n\n  background-color: var(--main-accent-color);\n  color: var(--main-accent-opposite-color);\n}\n\n.buttonPrimary:hover {\n  background-color: var(--main-accent-hover-color);\n\n}\n\n.fullWidth{\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonPrimary": `PrimaryButton_buttonPrimary__AVcRs`,
	"fullWidth": `PrimaryButton_fullWidth__Zp2je`
};
export default ___CSS_LOADER_EXPORT___;
