import React, {useContext, useEffect, useState} from "react";
import './Definition.css'
import {Label} from "../ui/label/Label";
import {
	createDefinition,
	deleteDefinition,
	findDefinitionByUserDefinitionLanguage,
	updateDefinition
} from "../../providers/CardService";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import {InputField} from "../ui/input_field/InpitField";

export const Definition = (props) => {
	
	const [definition, setDefinition] = useState(props.definition)
	const [isChanged, setIsChanged] = useState(false);
	let delay;
	
	//ToDo change create/update time to when field become non active
	const callDefinitionApi = async (definition) => {
		if (definition?.id && isChanged) {
			update(definition)
			return;
		}
		
		let existedDefinition = await findDefinition(definition);
		
		if (existedDefinition.length) {
			setDefinition({...definition, id: existedDefinition[0].id});
			setIsChanged(true);
			return
		}
		
		if (!definition.value) {
			return
		}
		
		let created = await createDefinition(definition);
		setDefinition({...definition, id: created.id});
	}
	
	const findDefinition = async (definition) => {
		return await findDefinitionByUserDefinitionLanguage(definition)
				.then(r => {
					return r.content
				}).catch(e => {
					console.error(e)
				})
	}
	
	const update = (definition) => {
		updateDefinition(definition)
				.catch(e => {
					console.error(e);
				})
	}
	
	const create = (definition) => {
		createDefinition(definition)
				.catch(e => {
					console.error(e);
				})
	}
	
	//ToDo move to study card
	const callStudyCardApi = (definition) => {
		let card = props.studyCard;
		if (!definition?.id || !definition?.value) {
			return;
		}
		
		if (props.isTerm) {
			card.expression = definition;
		} else {
			card.definitions.push(definition);
		}
		props.persistCardCallback(card);
	}
	
	const callDeleteDefinition = () => {
		props.deleteDefinitionFromCard(definition.id)
				.then(() => {
					if (definition?.id) {
						deleteDefinition(definition)
								.then(() => console.log("definition deleted"))
					}
				})
	}
	
	const [isMount, setIsMount] = useState(false);
	useEffect(() => {
		if (isMount) {
			delay = setTimeout(() => {
				callDefinitionApi(definition)
						.then(r => callStudyCardApi(definition))
						.catch(e => {
							console.log(e)
						})
			}, 3000)
			return () => clearTimeout(delay)
		} else {
			setIsMount(true)
		}
	}, [definition]);
	
	return <div className="card-line">
		<div className="card-input-block">
			<label className="card-input-block-line">
				<InputField
						id={`expression${definition.id}`}
						placeholder={`Enter in ${props.language.value}`}
						value={definition.value}
						onChangeCallback={e => {
							setDefinition({
								...definition,
								value: e.target.value
							});
							setIsChanged(true);
						}}
				/>
				{!props.isTerm && definition.value ?
						<RemoveCircleOutlineIcon
								className="delete-definition-button"
								onClick={() => callDeleteDefinition()}/>
						: <span/>
				}
			</label>
			<Label value={props.label} isUpperCase={true}/>
		</div>
	</div>
}
