import classes from "./ResultAnimation.module.css";
import React from "react";

export const SuccessAnimation = ({color = '#4EDA27FF', text}) => {
    return (
        <div className={classes.container}>
            <div className={classes.circle} style={{"borderColor": color}}>
                <div className={[classes.tick, classes.pop].join(' ')} color={color}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28"
                         height="28">
                        <path
                            d="M11.637 20.286a2.41 2.41 0 0 1-3.411 0L2.11 14.17a2.42 2.42 0 0 1 0-3.413c.943-.94 2.47-.94 3.41 0l4.412 4.412L22.87 2.23a2.41 2.41 0 1 1 3.411 3.411L11.637 20.286z"
                            fill={color} fillRule="evenodd"/>
                    </svg>
                </div>
            </div>
            {
                text && <div className={classes.message} style={{"color": color}}>
                    {text}
                </div>
            }
        </div>
    );
}

export const FailAnimation = ({color = '#D7112F', text}) => {
    return (
        <div className={classes.container}>
            <div className={classes.circle} style={{"borderColor": color}} color={color}>
                <div className={[classes.tick, classes.pop].join(' ')} color={color}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="28" height="28"
                         viewBox="0 0 255 318.75" x="0px" y="0px" fill={color} fillRule="evenodd" clipRule="evenodd">
                        <g>
                            <polygon points="255,48 207,0 128,80 48,0 0,48 80,128 0,207 48,255 128,175 207,255 255,207 175,128 "/>
                        </g>
                    </svg>
                </div>
            </div>

        </div>
    );
}
