import * as React from 'react';
import {useState} from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import {NavLink, useNavigate} from "react-router-dom";
import {Login, Logout} from "@mui/icons-material";
import {toast} from "react-hot-toast";
import {logout} from "../providers/AuthProvider";
import {useAuth} from "../hooks/useAuth";
import {PrimaryButton} from "../app/components/ui/button/primary/PrimaryButton";

const AccountMenu = () => {
	const defaultUser =
			{
				userId: 1,
				userName: 'Theresia Kieth',
				userIcon: 'https://xsgames.co/randomusers/assets/avatars/female/0.jpg'
			}
	
	const authenticated = useAuth();
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const [user, setUser] = useState(defaultUser);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const navigate = useNavigate();
	
	const handleLogout = () => {
		logout();
		navigate("/");
		handleClose();
		toast.success('You\'re safely logged out!')
	}
	const handleClose = () => {
		setAnchorEl(null);
	};
	
	return (
			<React.Fragment>
				<Box sx={{
					display: 'flex',
					alignItems: 'center',
					textAlign: 'center'
				}}>
					{/*{authenticated && <Typography sx={{minWidth: 100}}>Profile</Typography>}*/}
					{/*{authenticated &&*/}
					{/*    <IconButton*/}
					{/*        // onClick={handleClick}*/}
					{/*        size="small"*/}
					{/*        sx={{ml: 2}}*/}
					{/*    >*/}
					{/*        <AddCircleOutlineIcon*/}
					{/*            sx={{width: 40, height: 40, stroke: "#ffffff", strokeWidth: 1 }}*/}
					{/*        >*/}
					
					{/*        </AddCircleOutlineIcon>*/}
					{/*    </IconButton>*/}
					{/*}*/}
					
					{/*<PrimaryButton>*/}
					{/*	+ Add New Study Set*/}
					{/*</PrimaryButton>*/}
					
					<Tooltip title={user.userName}>
						<IconButton
								onClick={handleClick}
								size="small"
								sx={{ml: 2}}
								aria-controls={open ? 'account-menu'
										: undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
						>
							{authenticated ? <Avatar src={user.userIcon}
							                         alt={user.userName}/>
									: <Avatar>A</Avatar>
							}
						</IconButton>
					</Tooltip>
				</Box>
				<Menu
						anchorEl={anchorEl}
						id="account-menu"
						open={open}
						onClose={handleClose}
						onClick={handleClose}
						PaperProps={{
							elevation: 0,
							sx: {
								overflow: 'visible',
								filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
								mt: 1.5,
								'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
								},
								'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
								},
							},
						}}
						transformOrigin={{horizontal: 'right', vertical: 'top'}}
						anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
				>
					<MenuItem onClick={handleClose}>
						<Avatar/>
						<NavLink to="/profile">Profile</NavLink>
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<Avatar/> My account
					</MenuItem>
					<Divider/>
					<MenuItem onClick={handleClose}>
						<ListItemIcon>
							<PersonAdd fontSize="small"/>
						</ListItemIcon>
						Add another account
					</MenuItem>
					<MenuItem onClick={handleClose}>
						<ListItemIcon>
							<Settings fontSize="small"/>
						</ListItemIcon>
						Settings
					</MenuItem>
					{authenticated ?
							<MenuItem onClick={handleLogout}>
								<ListItemIcon>
									<Logout fontSize="small"/>
								</ListItemIcon>
								Logout
							</MenuItem>
							: <MenuItem onClick={handleClose}>
								<ListItemIcon>
									<Login fontSize="small"/>
								</ListItemIcon>
								<NavLink to="/login">Login</NavLink>
							</MenuItem>}
				
				</Menu>
			</React.Fragment>
	);
}

export default AccountMenu;
