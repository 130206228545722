// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label {
  padding-left: 0.3rem;
  font-size: 12px;
  font-family: var(--main-font-family);
  color: var(--main-inactive-text-color);
  vertical-align: top;
}

.label-uppercase{
  text-transform: uppercase;
}
`, "",{"version":3,"sources":["webpack://./src/component/ui/label/Label.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,eAAe;EACf,oCAAoC;EACpC,sCAAsC;EACtC,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".label {\n  padding-left: 0.3rem;\n  font-size: 12px;\n  font-family: var(--main-font-family);\n  color: var(--main-inactive-text-color);\n  vertical-align: top;\n}\n\n.label-uppercase{\n  text-transform: uppercase;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
