export const API_BASE_URL = process.env.REACT_APP_USERS_BASE_URL;
export const ACCESS_TOKEN = 'accessToken';
export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect'
export const GOOGLE_AUTH_URL = API_BASE_URL + '/users/oauth2/authorization/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/users/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/users/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const TOKEN_ITEM_NAME = "token";
export const REFRESH_TOKEN_ITEM_NAME = "refresh_token";
export const CHECK_AUTH_EVENT_NAME = 'checkAuthenticated';
export const UPDATE_STUDY_SET_LIST_EVENT = 'updateStudySetEvent';


