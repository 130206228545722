import React from 'react'
import {Skeleton} from "@mui/material";
import './StudyCardSkeleton.css'
export const StudyCardSkeleton = () => {
	return (
			<div className="study-card-skeleton">
				<Skeleton
						className="skeleton"
						variant="rounded"
						animation="wave"
				/>
			</div>
	)
}
