import React, {useEffect, useState} from 'react';
import './StudyCard.css'
import {DeleteForeverOutlined} from "@mui/icons-material";
import {Definition} from "../definition/Definition";
import {
	createStudyCard,
	deleteStudyCard,
	getStudyCardById,
	updateStudyCard
} from "../../providers/CardService";
import {StudyCardSkeleton} from "../ui/skeleton/study_card_skeleton/StudyCardSkeleton";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const StudyCard = ({
	card,
	trigger,
	studiedLanguage,
	nativeLanguage,
	addCardToStudySet,
	deleteCardFromStudySet
}) => {
	const [studyCard, setStudyCard] = useState(null);
	const [alert, setAlert] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	// ToDo think about delete definitions and use definition from studyCard
	const [definitions, setDefinitions] = useState(null);
	const [reload, setReload] = useState(false);
	
	useEffect(() => {
		if (!card?.id) {
			setStudyCard(card);
			setDefinitions([{}]);
			setIsLoading(false);
			return;
		}
		getStudyCardById(card.id)
				.then(resp => {
					setStudyCard(resp);
					setDefinitions(resp.definitions);
					setIsLoading(false);
				});
		
	}, []);
	
	useEffect(() => {
		if (trigger > 0 && studyCard.id === undefined) {
			setAlert(true);
			setTimeout(() => {
				setAlert(false);
			}, 3000)
		}
	}, [trigger]);
	
	useEffect(() => {
		if (reload) {
			setIsLoading(true)
			getStudyCardById(card.id)
					.then(resp => {
						setStudyCard(resp);
						setDefinitions(resp.definitions);
					})
					.then(() => setReload(false))
					.then(() => setIsLoading(false))
		}
	}, [reload])
	
	const persistCard = (card) => {
		if (card?.id) {
			updateCard(card);
		} else {
			createCard(card);
		}
	}
	
	const createCard = (card) => {
		createStudyCard(card)
				.then(resp => {
					studyCard.id = resp.id;
					setStudyCard(studyCard);
					callAddCardToStudySet(card, resp.id);
					return resp;
				}).then(resp => callAddCardToStudySet(card, resp.id))
	}
	
	const updateCard = (card) => {
		updateStudyCard(card)
				.finally(
						() => {
							setStudyCard(card);
							callAddCardToStudySet(card, card.id);
						})
	}
	
	const callAddCardToStudySet = (card, id) => {
		let count = card.definitions
				.filter(item => item.id != null).length
		if (card.expression != null && count > 0) {
			addCardToStudySet(id)
		}
	}
	const getDefinition = (item, language) => {
		return {
			id: item?.id ? item.id : undefined,
			value: item?.value ? item.value : undefined,
			languageId: language.id
		}
	}
	
	const remove = (studyCard) => {
		deleteCardFromStudySet(studyCard.id)
				.then(() => deleteStudyCard(studyCard.id))
	}
	
	const addNewDefinition = () => {
		setDefinitions(items => [...items, {}]);
	}
	
	const deleteDefinition = (definitionId) => {
		if (definitionId == null) {
			studyCard.definitions.filter(item => item !== null)
			setStudyCard(studyCard);
			return Promise.resolve();
		}
		studyCard.definitions = studyCard.definitions.filter(
				item => item.id !== definitionId);
		return updateStudyCard(studyCard)
				.then(() => setReload(true));
	}
	
	return (
			<article> {isLoading ?
					<StudyCardSkeleton/>
					:
					<div className={
						alert
								? "study-card".concat(' ', "alert-card")
								: "study-card"}>
						<div className="card-toolbar">
							<span className="card-toolbar-counter"/>
							<DeleteForeverOutlined
									className="delete-card-button"
									onClick={() => remove(studyCard)}/>
						</div>
						<div className="card-body">
							<ul className='card-body-column'>
								<li key={0} className='card-body-item'>
									<Definition definition={
										getDefinition(
												studyCard.expression,
												studiedLanguage
										)}
									            persistCardCallback={persistCard}
									            studyCard={studyCard}
									            label="term"
									            language={studiedLanguage}
									            isTerm={true}
									/>
								</li>
							</ul>
							<ul className='card-body-column'>
								{definitions.map((definition, index) => {
									return <li className="definition-item"
									           key={index}>
										<Definition definition={
											getDefinition(definition,
													nativeLanguage)
										}
										            persistCardCallback={persistCard}
										            studyCard={studyCard}
										            label="definition"
										            language={nativeLanguage}
										            isTerm={false}
										            deleteDefinitionFromCard={deleteDefinition}
										/>
									</li>
									
								})}
								<li className="add-definition-button-item">
									<button className="add-definition-button"
									        onClick={addNewDefinition}>
										<AddCircleOutlineIcon
												className="add-definition-button-icon"/>
										<span>add definition</span>
									</button>
								</li>
							</ul>
						</div>
					</div>
			}
			</article>
	)
}

