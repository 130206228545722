const USERS_API_BASE_URL = process.env.REACT_APP_USERS_BASE_URL;

export function signUp(signUpRequest) {
	return fetch(
			USERS_API_BASE_URL + "/api/v1/auth/sign-up",
			{
				method: 'POST',
				body: JSON.stringify(signUpRequest),
				headers: {
					'Content-Type': 'application/json'
				}
			})
			.then(resp => resp.body)
}

export function verifyEmail(token) {
	return fetch(
			USERS_API_BASE_URL + "/api/v1/registration/email/confirmation",
			{
				method: 'POST',
				body: JSON.stringify({'token': token}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
	)
}

export async function login(loginRequest) {
	return await fetch(
			USERS_API_BASE_URL + "/api/v1/auth/sign-in",
			{
				method: 'POST',
				body: JSON.stringify({
					username: loginRequest.email,
					password: loginRequest.password
				}),
				headers: {
					'Content-Type': 'application/json'
				}
			}
	);
}
