// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skeleton {
  width: 100%;
  min-height: 186px;
}
`, "",{"version":3,"sources":["webpack://./src/component/ui/skeleton/study_card_skeleton/StudyCardSkeleton.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".skeleton {\n  width: 100%;\n  min-height: 186px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
