export const loginPath = "/login";
export const OWN_URL = "http://becomepolyglot.org";
export const API_BASE_URL = 'http://localhost:8888';
export const ACCESS_TOKEN = 'accessToken';
export const OAUTH2_REDIRECT_URI = 'http://localhost:3000/oauth2/redirect'
export const GOOGLE_AUTH_URL = API_BASE_URL + '/users/oauth2/authorization/google?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const FACEBOOK_AUTH_URL = API_BASE_URL + '/users/oauth2/authorize/facebook?redirect_uri=' + OAUTH2_REDIRECT_URI;
export const GITHUB_AUTH_URL = API_BASE_URL + '/users/oauth2/authorize/github?redirect_uri=' + OAUTH2_REDIRECT_URI;



