import {Skeleton} from "@mui/material";
import React from "react";
import {StudyCardSkeleton} from "../study_card_skeleton/StudyCardSkeleton";

export const StudySetSkeleton = () => {
	return (
			<div className="study-set-skeleton">
				<Skeleton variant="text" sx={{ fontSize: '2rem', width: '50%'}} />
				<Skeleton variant="text" sx={{ fontSize: '2rem', width: '50%', marginBottom: '30px' }} />
				<StudyCardSkeleton/>
			</div>
	)
}
