import {useEffect, useState} from "react";
import {getDefinitionById} from "../../../providers/DictionaryService";
import classes from "./ExaminationCard.module.css"
import {InputField} from "../../ui/input_field/InpitField";
import {checkCard} from "../../../providers/AggregatorService";
import {FailAnimation, SuccessAnimation} from "../../ui/animated/animated_result/ResultAnimation";
import {useNavigate} from "react-router-dom";

// ToDo fix reload page on each success or false
export const ExaminationCard = ({card}) => {

    const [examinationCard, setExaminationCard] = useState(card);
    const [isLoading, setIsLoading] = useState(true);
    const [definition, setDefinition] = useState(null)
    const [answer, setAnswer] = useState('')
    const [isChecked, setIsChecked] = useState(false);
    const [ok, setOk] = useState(null);
    const [response, setResponse] = useState();
    const [next, setNext] = useState(false);
    const router = useNavigate();

    useEffect(() => {
        getDefinitionById(examinationCard.definitionId)
                .then(data => setDefinition(data))
                .then(() => setIsLoading(false))
    }, []);

    const doOnFailResult = (resp) => {
        setOk(false)
    }

    const doOnSuccessResult = (resp) => {
        setOk(true)
    }

    const checkAnswer = () => {
        checkCard(examinationCard.id, answer)
            .then(resp => {
                setIsChecked(true)
                setResponse(resp);
                setNext(true);
                if (resp.result === "FAIL") {
                    doOnFailResult(resp)
                } else {
                    doOnSuccessResult(resp)
                }
            })

    }

    useEffect(() => {
        if (next) {
            setTimeout(() =>
                router(0)
                , 3000)
        }
    }, [next]);

    const resultAnimation = () => {
        return ok ? <SuccessAnimation/> :
            <div>
                <FailAnimation/>
                <h2>{answer}</h2>
                <h2>{response.definition}</h2>
            </div>
    }
    return (
        <article>
            {
                isLoading ? <div>
                        ...Loading
                    </div> :
                    <section className={classes.commonSection}>
                        <div className={classes.examinationCard}>
                            <h3 className={classes.definitionValue}>
                                {definition.value}
                            </h3>
                            {!isChecked ?
                                <InputField
                                    id={"answer"}
                                    value={answer}
                                    onChangeCallback={e => setAnswer(
                                        e.target.value)}
                                /> : resultAnimation()
                            }
                            <button type="button"
                                    className={classes.checkButton}
                                    onClick={checkAnswer}
                            >
                                Check
                            </button>
                        </div>
                    </section>
            }
        </article>
    )
}

export const FinishExaminationCard = () =>{
    const router = useNavigate();
    return (
            <article>
                {
                            <section className={classes.commonSection}>
                                <div className={classes.examinationCard}>
                                    <h3 className={classes.definitionValue}>
                                        Congrats!!!
                                    </h3>
                                    <h4 className={classes.definitionValue}>
                                        It looks like you are finishing all cards today!!!
                                    </h4>
                                    <button type="button"
                                            className={classes.checkButton}
                                            onClick={() => router("/")}
                                    >
                                        Finish
                                    </button>
                                </div>
                            </section>
                }
            </article>
    )
}
