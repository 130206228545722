import {useEffect, useState} from "react";
import {
	isAuthenticated
} from "../providers/AuthProvider";
import {subscribe, unsubscribe} from "../events";
import {CHECK_AUTH_EVENT_NAME} from "../constants";

export function useAuth() {
	const [authenticated, setAuthenticated] = useState(isAuthenticated());
	
	useEffect(() => {
		subscribe(CHECK_AUTH_EVENT_NAME, () => {
			setAuthenticated(isAuthenticated())
		})
		return () => {
			unsubscribe(CHECK_AUTH_EVENT_NAME, () => setAuthenticated(false))
		}
	}, []);
	
	return authenticated;
}
