import React, {useEffect, useState} from "react";
import classes from "./StudySetCard.module.css";
import {useNavigate} from "react-router-dom";
import {RatingStar} from "../../../app/components/ui/rating/star/RatingStar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsis} from "@fortawesome/free-solid-svg-icons/faEllipsis";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
	BorderLinearProgress
} from "../../../app/components/ui/progress/linear/BorderLinearProgress";
import {
	createCourseFromStudySet,
	getCourseByStudySetId
} from "../../../providers/CourseService";
import {deleteStudySet} from "../../../providers/StudySetService";
import {publish} from "../../../events";
import {UPDATE_STUDY_SET_LIST_EVENT} from "../../../constants";

export const StudySetCard = ({studySet}) => {
	
	return <NewCard card={studySet}/>
	
}

export const NewCard = ({card}) => {
	
	let mockData = [
		{
			image: "https://thumbs4.imagebam.com/3b/8f/7c/MEUM28D_t.jpg",
			category: [
				{
					id: 10,
					name: "Café"
				}
			],
			rating: "5"
		},
		{
			image: "https://thumbs4.imagebam.com/30/56/59/MEUM2B3_t.jpg",
			category: [{
				id: 1,
				name: "Diner"
			}],
			rating: "4.65"
		}
	]
	const [cover, setCover] = useState();
	useEffect(() => {
		if (card.image) {
			setCover(card.image)
		} else {
			setCover(mockData[Math.floor(
					Math.random() * mockData.length)].image);
		}
	}, []);
	const navigate = useNavigate()
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClickOnMenu = (event, card) => {
		event.stopPropagation();
		console.log(`Click on card menu ` + JSON.stringify(card));
		setAnchorEl(event.currentTarget);
	};
	const handleCloseOnMenu = (event) => {
		event.stopPropagation();
		setAnchorEl(null);
	};
	
	const clickOnCard = (card) => {
		console.log("Click on card " + JSON.stringify(card));
		getCourseByStudySetId(card.id)
				.then(resp => {
					if(resp.length) {
						return resp[0].id
					} else {
						return createCourseFromStudySet(card.id)
								.then(r => r.id)
					}
				})
				.then(id => navigate(`/examination/course/${id}`))
				.catch(err => {console.error(err)})
	}
	
	const editCard = (event, card) => {
		event.stopPropagation();
		console.log(`Edit card with id ${card.id}`);
		setAnchorEl(null);
		navigate(`/study-sets/${card.id}`)
	}
	
	const deleteCard = (event, card) => {
		event.stopPropagation();
		setAnchorEl(null);
		deleteStudySet(card)
				.then(() => publish(UPDATE_STUDY_SET_LIST_EVENT));
	}
	
	return <article className={classes.card} onClick={() => clickOnCard(card)}>
		<div className={classes.imageSection}>
			<img className={classes.image} src={cover}
			     alt={card.description}/>
			<div className={classes.cardMenu}
			     onClick={(e) => handleClickOnMenu(e, card)}>
				<FontAwesomeIcon icon={faEllipsis}/>
			</div>
			<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={open}
					onClose={handleCloseOnMenu}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
			>
				<MenuItem onClick={(event) => editCard(event,
						card)}>Edit</MenuItem>
				<MenuItem onClick={(event) => deleteCard(event,
						card)}>Delete</MenuItem>
			</Menu>
		</div>
		<div className={classes.content}>
			<h2 className={classes.title}>{card.title}</h2>
			<p className={classes.description}>{card.description}</p>
		</div>
		<ProgressView card={card}/>
		<CardFooter card={card}/>
	</article>
}

export const CardFooter = ({card}) => {
	const defaultRating = 5;
	const [rating, setRating] = useState();
	useEffect(() => {
		if (card.rating) {
			setRating(card.rating)
		} else {
			setRating(defaultRating)
		}
	}, []);
	return <div className={classes.footer}>
		<CategoryView card={card}/>
		<div className={[classes.uIconGroup].join(' ')}>
			<RatingStar/>
			<span>{rating}</span>
		</div>
	</div>
}

export const ProgressView = ({card}) => {
	
	const [progress, setProgress] = useState(0)
	useEffect(() => {
		if(card.process) {
			setProgress(card.process)
		} else {
			setProgress(Math.floor(Math.random() * 101))
		}
	}, []);
	
	return <div className={classes.progress}>
		<span className={classes.progressBarLabel}>progress:</span>
		<BorderLinearProgress variant="determinate" value={progress}/>
	</div>
}

export const CategoryView = ({card}) => {
	const mockData = [{
		id: 100,
		name: "A1 level"
	}];
	const [tags, setTags] = useState(mockData);
	
	useEffect(() => {
		if (card.category) {
			setTags(card.category)
		} else {
			setTags(mockData)
		}
	}, []);
	
	return <ul className={classes.categoryList}>
		{tags.map(item => {
			return <li key={item.id}
			           className={[classes.category, classes.uIconGroup,
				           classes.uChip, classes.uChipOutline].join(' ')}>
				{item.name}
			</li>
		})}
	</ul>
}
