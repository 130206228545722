// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app{
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-top-box{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.app-body{
    width: 100%;
}

.s-alert-box {
    min-width: 250px;
}

.s-alert-close::before, .s-alert-close::after {
    width: 2px;
}

.footer{
    margin-top: 5rem;
    margin-bottom: 2rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".app{\n    display: flex;\n    width: 100%;\n    height: 100%;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.app-top-box{\n    position: relative;\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.app-body{\n    width: 100%;\n}\n\n.s-alert-box {\n    min-width: 250px;\n}\n\n.s-alert-close::before, .s-alert-close::after {\n    width: 2px;\n}\n\n.footer{\n    margin-top: 5rem;\n    margin-bottom: 2rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
