// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RatingStar_ratingStar__KIKcB {
  width: 1.25rem;
  height: 1.25rem;
  color: var(--rating-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ui/rating/star/RatingStar.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,0BAA0B;AAC5B","sourcesContent":[".ratingStar {\n  width: 1.25rem;\n  height: 1.25rem;\n  color: var(--rating-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ratingStar": `RatingStar_ratingStar__KIKcB`
};
export default ___CSS_LOADER_EXPORT___;
