import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {verifyEmail} from "../../providers/UserProvider";
import {Alert} from "@mui/material";

function useQuery() {
	const {search} = useLocation();
	return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const EmailVerification = () => {
	const SUCCESS_MESSAGE = "Email verified"
	const FAIL_MESSAGE = "There is some problem with email verification. Please try later"
	const navigator = useNavigate();
	let query = useQuery();
	let [isVerified, setIsVerified] = useState(false);
	useEffect(() => {
		let token = query.get("token")
		verifyEmail(token)
				.then(r => r.ok ? setIsVerified(true) : setIsVerified(false))
	}, []);
	
	useEffect(() => {
		setTimeout(() =>
						navigator('/')
				, 3000)
		
	}, [isVerified]);
	return (
			<section>
				{isVerified ? <Alert
						severity="success">{SUCCESS_MESSAGE}</Alert> :
						<Alert severity="error">{FAIL_MESSAGE}</Alert>}
			</section>
	)
}
