import {v4 as uuid} from 'uuid';
import {axiosInstance} from "../interceptors/AxiousInstance";
import {DICTIONARY_API_BASE_URL} from "../constants/UrlBasePathConst";

export const getStudySetById = async (id) => {
	
	return (await axiosInstance
			.get(`${DICTIONARY_API_BASE_URL}/api/v1/study-sets/${id}`))
			.data;
}

export const updateStudySetById = async (studySet) => {
	let updateStudySetRequest = {
		title: studySet.title,
		description: studySet.description,
		requestId: uuid()
	}
	return (await axiosInstance
			.put(`${DICTIONARY_API_BASE_URL}/api/v1/study-sets/${studySet.id}`, updateStudySetRequest))
			.data
}

export const getStudyCardById = async (id) => {
	return (await axiosInstance
			.get(`${DICTIONARY_API_BASE_URL}/api/v1/cards/${id}`))
			.data
}

export const findDefinitionByUserDefinitionLanguage = async (definition) => {
	let url = new URL(`${DICTIONARY_API_BASE_URL}/api/v1/definitions`);
	url.searchParams.append("value", definition.value)
	url.searchParams.append("languageId", definition.languageId)
	
	let params = {
		"value": definition.value,
		"languageId": definition.languageId
	}
	return (await axiosInstance
			.get(`${DICTIONARY_API_BASE_URL}/api/v1/definitions`, {params}))
			.data;
	
};

export const createDefinition = async (definition) => {
	let createDefinitionRequest = {
		value: definition.value.trim(),
		languageId: definition.languageId,
		requestId: uuid()
	}
	
	return (await axiosInstance
			.post(`${DICTIONARY_API_BASE_URL}/api/v1/definitions`,
					createDefinitionRequest))
			.data
}

export const updateDefinition = async (definition) => {
	let updateDefinitionRequest = {
		value: definition.value.trim(),
		languageId: definition.languageId,
		requestId: uuid()
	}
	
	return (await axiosInstance
			.put(`${DICTIONARY_API_BASE_URL}/api/v1/definitions/${definition.id}`, updateDefinitionRequest))
			.data
}

export const deleteDefinition = async (definition) => {
	return (await axiosInstance
			.delete(`${DICTIONARY_API_BASE_URL}/api/v1/definitions/${definition.id}`))
			.data
}

export const createStudyCard = async (studyCard) => {
	let definitionIds = studyCard.definitions
			.filter(item => item.id)
			.map(item => {
				return {
					id: item.id
				}
			})
	let cardCreateRequest = {
		requestId: uuid(),
		expressionDefinitionId: studyCard.expression?.id,
		definitions: definitionIds
	}
	
	return (await axiosInstance
			.post(`${DICTIONARY_API_BASE_URL}/api/v1/cards`, cardCreateRequest))
			.data
}

export const updateStudyCard = async (studyCard) => {
	//ToDo remove when find where doubles created
	let definitionIds = [...new Map(studyCard.definitions.map((item) => [item["id"], item])).keys()]
			.map(item => {
				return {id: item};
			});
	let cardUpdateRequest = {
		requestId: uuid(),
		expressionDefinitionId: studyCard.expression?.id,
		definitions: definitionIds
	}
	
	return (await axiosInstance
			.put(`${DICTIONARY_API_BASE_URL}/api/v1/cards/${studyCard.id}`,
					cardUpdateRequest))
			.data
}

export const addStudySetToStudyCard = async (studySetId, studyCardId) => {
	return (await axiosInstance.post(
			`${DICTIONARY_API_BASE_URL}/api/v1/study-sets/${studySetId}/card/${studyCardId}`))
			.data
}

export const deleteStudyCardFromStudySet = async (studySetId, studyCardId) => {
	return (await axiosInstance
			.delete(`${DICTIONARY_API_BASE_URL}/api/v1/study-sets/${studySetId}/card/${studyCardId}`))
			.data;
}

export const deleteStudyCard = async (studyCardId) => {
	if(studyCardId == null) {
		return;
	}
	
	return (await axiosInstance.delete(
			`${DICTIONARY_API_BASE_URL}/api/v1/cards/${studyCardId}`))
			.data;
}
