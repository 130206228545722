import React from 'react';
import "./Landing.css"
import imageMale4 from "../../img/male-4.png"
import imageMale21 from "../../img/male-21.png"
import languageLearning from "../../img/language-learning.jpeg"
import learn from "../../img/learn.jpeg"
import student from "../../img/student.jpeg"
import laptop from "../../img/laptop.jpeg"
import {RatingStar} from "../../app/components/ui/rating/star/RatingStar";
import {
	PrimaryButton
} from "../../app/components/ui/button/primary/PrimaryButton";
import {useNavigate} from "react-router-dom";
import {loginPath} from "../../app/ApiConstant";

export const Landing = () => {
	
	const navigate = useNavigate();
	const forwardToLoginPage = () => {
		navigate(loginPath)
	}
	
	const registerButtonContent = "Register Now"
	return (
			<main>
				<div className="section-spacing">
					
					<section className="section-title">
						<div className="section-title-content">
							<div>
								<div className="section-title-content-wrapper">
									<h1 className="section-title-content-header">
										Learn Languages with BecomePolyglot
									</h1>
									<h2 className="section-title-content-text">
										Discover a fun and interactive way to
										learn languages with LingoEdu, your
										personal language learning app.
									</h2>
								</div>
								<div className="section-title-button-wrapper">
									<PrimaryButton onClick={forwardToLoginPage}>
										{registerButtonContent}
									</PrimaryButton>
								</div>
								<div className="section-title-rating-wrapper">
									<div className="section-title-rating-star-wrapper">
										<RatingStar/>
										<RatingStar/>
										<RatingStar/>
										<RatingStar/>
										<RatingStar/>
									</div>
									<div className="rating-item">
										Personalized lessons
									</div>
									<div className="rating-item">
										Interactive features
									</div>
								</div>
								<div className="section-title-review-wrapper">
									<img
											src={imageMale4}
											alt="Edward Wilson"
											className="section-title-review-icon"/>
									<div>
										<p
												className="section-title-review-content">
											“LingoEdu has made learning a new
											language so much fun!”
										</p>
										<p className="section-title-reviewer">
											Edward Wilson
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="section-title-image-wrapper">
							<div
									className="section-title-image-wrapper-container">
								<div className="hidden section-title-image-background-wrapper">
									<div className="section-title-image-background-first"></div>
									<svg className="section-title-image-background-second"
									     width="404"
									     height="392" fill="none"
									     viewBox="0 0 404 392">
										<defs>
											<pattern
													id="837c3e70-6c3a-44e6-8854-cc48c737b659"
													x="0" y="0" width="20"
													height="20"
													patternUnits="userSpaceOnUse">
												<rect x="0" y="0" width="4"
												      height="4"
												      className="section-title-image-background-second-color"
												      fill="currentColor"></rect>
											</pattern>
										</defs>
										<rect width="404" height="392"
										      fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"></rect>
									</svg>
								</div>
								
								<div
										className="section-title-image-content">
									<img
											className="section-title-image"
											src={languageLearning}
											alt="BecomePolyglot.org, language learning"/>
								</div>
							</div>
						</div>
					</section>
					{/*ToDo fix space-y-24*/}
					<section className="section-content-container space-y-24">
						<div>
							<div className="section-content">
								<div
										className="section-content-right space-y-6">
									<div>
										<h2 className="section-content-header">
											Learn at your own pace
										</h2>
										<div className="section-content-text">
											LingoEdu allows you to learn
											languages at your own pace. Whether
											you're a
											beginner or advanced learner, our
											app provides personalized lessons
											tailored to
											your level and goals.
										</div>
									</div>
									<div></div>
								</div>
								<div className="section-content-image-right-content">
									<div
											className="section-content-image-right-wrapper">
										<img
												src={learn}
												className="section-content-image-right"
												alt="Learn at your own pace"/>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="section-content">
								<div
										className="section-content-left space-y-6">
									<div>
										<h2 className="section-content-header">
											Interactive and engaging lessons
										</h2>
										<div className="section-content-text">
											Our app offers interactive and
											engaging lessons that make language
											learning
											enjoyable. With features like
											quizzes, games, and real-life
											conversations,
											you'll stay motivated and immersed
											in the language.
										</div>
									</div>
									<div></div>
								</div>
								<div className="section-content-image-left-content">
									<div className="section-content-image-left-wrapper">
										<img src={student}
										     className="section-content-image-left"
										     alt="Interactive and engaging lessons"/>
									</div>
								</div>
							</div>
						</div>
						<div>
							<div className="section-content">
								<div className="section-content-right space-y-6">
									<div>
										<h2 className="section-content-header">
											Track your progress
										</h2>
										<div className="section-content-text">
											LingoEdu helps you track your
											progress and see your improvement
											over time. Set
											goals, earn badges, and receive
											feedback to stay motivated and
											focused on your
											language learning journey.
										</div>
									</div>
									<div></div>
								</div>
								<div className="section-content-image-right-content">
									<div className="section-content-image-right-wrapper">
										<img
												src={laptop}
												className="section-content-image-right"
												alt="Track your progress"/>
									</div>
								</div>
							</div>
						</div>
					</section>
					
					<section className="section-blockquote-container">
						<div className="section-blockquote-wrapper">
							<blockquote>
								<p className="section-blockquote-text">
									"I love how interactive and engaging the
									lessons are. It keeps me motivated to learn
									every day."
								</p>
								<footer className="section-blockquote-footer">
									<div className="section-blockquote-footer-wrapper">
										<div className="section-blockquote-footer-image-wrapper">
											<img
													src={imageMale21}
													className="section-blockquote-footer-image"
													alt="Learn languages"/>
										</div>
										<p className="section-blockquote-footer-text">
											Isaac Clark
										</p>
									</div>
								</footer>
							</blockquote>
						</div>
					</section>
					
					<section className="section-offer-container">
						<div aria-hidden="true"
						     className="section-offer-background-wrapper">
							<div className="section-offer-background"></div>
							<svg className="section-offer-background-image"
							     width="404" height="392" fill="none"
							     viewBox="0 0 404 392">
								<defs>
									<pattern
											id="8228f071-bcee-4ec8-905a-2a059a2cc4fb"
											x="0" y="0" width="20"
											height="20"
											patternUnits="userSpaceOnUse">
										<rect x="0" y="0" width="4" height="4"
										      className="section-title-image-background-second-color"
										      fill="currentColor"></rect>
									</pattern>
								</defs>
								<rect width="404" height="392"
								      fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)"></rect>
							</svg>
						</div>
						<div className="section-offer-content-wrapper">
							<div className="section-offer-content-wrapper-container">
								<div aria-hidden="true"
								     className="section-offer-content-background">
									<svg className="section-offer-content-background-image"
									     preserveAspectRatio="xMidYMid slice"
									     xmlns="http://www.w3.org/2000/svg"
									     fill="none" viewBox="0 0 1463 360">
										<path className="section-offer-content-background-image-1"
										      fill="currentColor"
										      d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"></path>
										<path className="section-offer-content-background-image-2"
										      fill="currentColor"
										      d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"></path>
									</svg>
								</div>
								<div className="section-offer-text">
									<h2 className="section-offer-text-header">
										Start your language learning journey now<span
											className="accent-text">.</span>
									</h2>
									<p className="section-offer-text-paragraph">
										<span>“Thanks to LingoEdu, I've been able to make significant progress in my language learning journey. Highly recommend it!”</span>
										<span> - Xander Carter</span>
									</p>
								</div>
								<div className="section-offer-button-wrapper">
									<PrimaryButton onClick={forwardToLoginPage}>
										{registerButtonContent}
									</PrimaryButton>
								</div>
							</div>
						</div>
					</section>
					
					<footer className="section-footer-container space-y-8">
						<p className="section-footer-text">© All rights
							reserved.</p>
					</footer>
				
				</div>
			</main>
	)
}
