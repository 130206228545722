import {Route, Routes} from "react-router-dom";
import Home from "../../home/Home";
import SignInSide from "../pages/login/SignIn";
import {StudySet} from "../../component/study_set/StudySet";
import {StudySetList} from "../../component/study_set_list/StudySetList";
import {CourseExamination} from "../../component/examination/CourseExamination";
import {
	EmailVerification
} from "../../component/email_verification/EmailVerification";
import OAuth2RedirectHandler from "../../user/oauth2/OAuth2RedirectHandler";
import NotFound from "../../common/NotFound";
import React from "react";

export const AuthorizedRoutes = () => {
	return (
				<Routes>
					<Route path="/" element={<Home/>}/>
					<Route path="/login" element={<SignInSide/>}/>
					<Route path="/study-sets/:id"
					       element={<StudySet/>}/>
					<Route path="/study-sets"
					       element={<StudySetList/>}/>
					<Route path="/examination/course/:id"
					       element={<CourseExamination/>}/>
					<Route path="/sign-up"
					       Component={(props) => <SignInSide
							       isSignUp={true}/>}/>
					<Route path="/registrationConfirm"
					       element={<EmailVerification/>}/>
					<Route path="/oauth2/redirect"
					       element={<OAuth2RedirectHandler/>}/>
					<Route path="*" element={<NotFound/>}/>
				</Routes>
	)
}
