import React from 'react';
import './App.css';
import {Toaster} from "react-hot-toast";
import {CommonHeader} from "./components/header/common_header/CommonHeader";
import {AuthProvider} from "../providers/AuthProvider";
import {AuthorizedRoutes} from "./routes/AuthorizedRoutes";
import {useAuth} from "../hooks/useAuth";
import {Copyright} from "./components/common/footer/copyright/Copyright";

function Application() {
	
	const authenticated = useAuth();
	
	return (
			<React.StrictMode>
				<AuthProvider>
					<div className="app">
						<Toaster
								position="top-right"
								reverseOrder={false}
						/>
						<div className="app-top-box">
							<CommonHeader/>
						</div>
						<div className="app-body">
							{authenticated ? <AuthorizedRoutes/> :
									<AuthorizedRoutes/>}
						</div>
						<div className="footer">
							<Copyright/>
						</div>
					
					</div>
				</AuthProvider>
			</React.StrictMode>
	);
}

export default Application;
