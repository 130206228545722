import {createTheme} from "@mui/material/styles";


const palettePrimaryMain = "rgb(255 0 110 / 1)";

export const defaultTheme = createTheme({
	palette: {
		primary: {
			main: palettePrimaryMain,
		}
	}
});
