import * as React from 'react';
import {useRef, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import {ThemeProvider} from '@mui/material/styles';
import {PrimaryButton} from "../../components/ui/button/primary/PrimaryButton";
import {toast} from "react-hot-toast";
import {useNavigate} from "react-router-dom";
import {Copyright} from "../../components/common/footer/copyright/Copyright";
import {defaultTheme} from "../../components/theme/AppDefaultTheme";
import {
	ValidatedTextField
} from "../../../component/ui/input_field/validated_text_field/ValidatedTextField";
import {emailValidator, passwordValidator} from "../../../util/Validator";
import {login} from "../../../providers/AuthProvider";
import {signUp} from "../../../providers/UserProvider";

export default function SignInSide(props) {

	const [isSignUp, setIsSignUp] = useState(props.isSignUp);
	
	return (
			<ThemeProvider theme={defaultTheme}>
				<Grid container component="main" sx={{height: '100vh'}}>
					<CssBaseline/>
					<Grid
							item
							xs={false}
							sm={4}
							md={7}
							sx={{
								backgroundImage: 'url(https://images4.imagebam.com/ea/b0/46/MERPT12_o.jpeg)',
								backgroundRepeat: 'no-repeat',
								backgroundColor: (t) =>
										t.palette.mode === 'light'
												? t.palette.grey[50]
												: t.palette.grey[900],
								backgroundSize: 'cover',
								backgroundPosition: 'center',
							}}
					/>
					{isSignUp ? <SignUpForm/> : <LoginForm/>}
				</Grid>
			</ThemeProvider>
	);
}

function LoginForm() {
	const navigate = useNavigate();
	
	const handleSubmit = async (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const loginRequest = {
			'email': data.get('email'),
			'password': data.get('password')
		};
		
		await login(loginRequest);
		toast.success('You\'re successfully logged in!');
		navigate("/");
	}
	
	return <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}
	             square>
		<Box
				sx={{
					my: 8,
					mx: 4,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
		>
			<Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
				<LockOutlinedIcon/>
			</Avatar>
			<Typography component="h1" variant="h5">
				Sign in
			</Typography>
			<Box component="form" noValidate onSubmit={handleSubmit}
			     sx={{mt: 1}}>
				<TextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
				/>
				<TextField
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
				/>
				<FormControlLabel
						control={<Checkbox value="remember" color="primary"/>}
						label="Remember me"
				/>
				<PrimaryButton type="submit" fullWidth={true}>
					Sign In
				</PrimaryButton>
				<Grid container>
					<Grid item xs>
						<Link href="#" variant="body2">
							Forgot password?
						</Link>
					</Grid>
					<Grid item>
						<Link href="/sign-up" variant="body2">
							{"Don't have an account? Sign Up"}
						</Link>
					</Grid>
				</Grid>
				<Copyright sx={{mt: 5}}/>
			</Box>
		</Box>
	</Grid>
}

function SignUpForm() {
	const navigate = useNavigate();
	const formValid = useRef({email: false, password: false, confirmPassword: false });

	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		if (data.get('password') !== data.get('confirm_password')) {
			alert('Password mismatch')
			return
		}
		const signUpRequest = {
			'email': data.get('email'),
			'password': data.get('password')
		};
		
		signUp(signUpRequest)
				.then(response => {
					toast.success('You\'re successfully signed up! Please verify your email')
					navigate("/")
				}).catch(error => {
			console.log((error && error.message)
					|| 'Oops! Something went wrong. Please try again!')
			toast.error((error && error.message)
					|| 'Oops! Something went wrong. Please try again!');
		});
	}
	
	return <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}
	             square>
		<Box
				sx={{
					my: 8,
					mx: 4,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}>
			<Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
				<LockOutlinedIcon/>
			</Avatar>
			<Typography component="h1" variant="h5">
				Sign Up
			</Typography>
			<Box component="form"
			     noValidate
			     onSubmit={handleSubmit}
			     sx={{mt: 1}}>
				<ValidatedTextField
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoFocus
						validator={emailValidator}
						onChange={isValid => (formValid.current.email = isValid)}
				/>
				<ValidatedTextField
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						validator={passwordValidator}
						onChange={isValid => (formValid.current.password = isValid)}
				/>
				
				<ValidatedTextField
						margin="normal"
						required
						fullWidth
						name="confirm_password"
						label="Confirm password"
						type="password"
						id="confirm_password"
						validator={passwordValidator}
						onChange={isValid => (formValid.current.confirmPassword = isValid)}
				/>
				<PrimaryButton type="submit" fullWidth={true}>
					Sign Up
				</PrimaryButton>
				<Copyright sx={{mt: 5}}/>
			</Box>
		</Box>
	</Grid>
}
