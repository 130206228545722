import {useContext, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom'
import {ACCESS_TOKEN} from "../../constants";
import {toast} from "react-hot-toast";

const OAuth2RedirectHandler = () => {

    const navigate = useNavigate();
    const location = useLocation()
    const {setAuthenticated} = useContext(AuthContext)

    const getUrlParameter = (name) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');

        let results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    const token = getUrlParameter('token');
    const error = getUrlParameter('error');

    useEffect(() => {
        if (token) {
            localStorage.setItem(ACCESS_TOKEN, token);
            setAuthenticated(true)
            toast.success('You\'re successfully logged in!')
            return navigate("/");
        } else {
            setAuthenticated(false)
            toast.error((error) || 'Oops! Something went wrong. Please try again!');
            return navigate("/login")
        }

    });

}

export default OAuth2RedirectHandler;
