import {v4 as uuid} from 'uuid';
import {axiosInstance} from "../interceptors/AxiousInstance";

const AGGREGATOR_API_BASE_URL= process.env.REACT_APP_AGGREGATOR_BASE_URL

export const getExaminationCardsByCourseId = async (courseId) => {
	let url = `${AGGREGATOR_API_BASE_URL}/api/v1/courses/${courseId}/cards`;
	return (await axiosInstance.get(url))
			.data;
}

export const checkCard = async (cardId, answer) => {
	let url = `${AGGREGATOR_API_BASE_URL}/api/v1/cards/${cardId}/examination`;
	let requestBody = {
		answer: answer.trim(),
		requestId: uuid()
	}
	return (await axiosInstance.post(url, requestBody))
			.data
}

