import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {getExaminationCardsByCourseId} from "../../providers/AggregatorService";
import {ExaminationCard, FinishExaminationCard} from "./card/ExaminationCard";

export const CourseExamination = () => {
	const params = useParams();
	const [isLoading, setIsLoading] = useState(true);
	const [card, setCard] = useState();
	const [isEmpty, setIsEmpty] = useState(false)
	
	useEffect(() => {
		getExaminationCardsByCourseId(params.id)
				.then((resp) => {
					if (resp.length > 0) {
						setCard(resp[0])
					} else {
						setIsEmpty(true)
					}
					
				})
				.then(() => setIsLoading(false))
	}, [])
	
	const showCard = () => {
		return isEmpty ?
				<FinishExaminationCard/> :
				<ExaminationCard card={card}/>
	}
	
	return (
			<div>
				{isLoading ?
						<div></div>
						:
						<div>
							{showCard()}
						</div>
				}
			</div>
	
	)
}
