import {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import {USERS_API_BASE_URL} from "../constants/UrlBasePathConst";
import {jwtDecode} from "jwt-decode";
import {toast} from "react-hot-toast";
import {publish, subscribe, unsubscribe} from "../events";
import {CHECK_AUTH_EVENT_NAME} from "../constants";

const AuthContext = createContext(null);
const SIGN_IN_URL = "/api/v1/auth/sign-in";
const TOKEN_ITEM_NAME = "token";
const REFRESH_TOKEN_ITEM_NAME = "refresh_token";

export const AuthProvider = ({children}) => {
	
	const [token, setToken_] = useState(localStorage.getItem(TOKEN_ITEM_NAME));
	const [isAuthorized, setIsAuthorized] = useState(false);
	
	const setToken = (newToken) => {
		setToken_(newToken);
	};
	
	useEffect(() => {
		
		const listenStorageChange = () => {
			console.log("From listener");
			//updateToken();
		}
		subscribe(CHECK_AUTH_EVENT_NAME, () => listenStorageChange())
		return () => unsubscribe(CHECK_AUTH_EVENT_NAME, listenStorageChange);
	}, []);
	
	const updateToken = () => {
		//axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true
		let accessToken = getAccessToken();
		console.log(isExpiredToken())
		if (!accessToken) {
			delete axios.defaults.headers.common["Authorization"];
			localStorage.removeItem(TOKEN_ITEM_NAME);
			setIsAuthorized(false);
			setToken(null);
			publish(CHECK_AUTH_EVENT_NAME);
			return;
		}
		
		// if (isExpiredToken()) {
		// 	console.log("Expired token");
		// 	refreshToken()
		// 			.then(accessToken => setToken(accessToken))
		// 			.then(() => console.log("Token refreshed"))
		// 	setIsAuthorized(isAuthenticated());
		// 	axios.defaults.headers.common["Authorization"] = "Bearer "
		// 			+ getAccessToken();
		//
		// 	publish(CHECK_AUTH_EVENT_NAME)
		// 	return;
		// }
		
		if (accessToken) {
			// axios.defaults.headers.common["Authorization"] = "Bearer "
			// 		+ getAccessToken();
			localStorage.setItem(TOKEN_ITEM_NAME, accessToken);
			setToken(accessToken);
			setIsAuthorized(isAuthenticated())
			publish(CHECK_AUTH_EVENT_NAME)
			return;
		}
		throw Error("Auth error");
	}
	
	const contextValue = {
		token,
		setToken,
		isAuthorized
	};
	
	return (
			<AuthContext.Provider
					value={contextValue}>{children}
			</AuthContext.Provider>
	);
}

export const isAuthenticated = () => {
	return !isExpiredToken();
}

export const logout = () => {
	localStorage.removeItem(TOKEN_ITEM_NAME);
	localStorage.removeItem(REFRESH_TOKEN_ITEM_NAME);
	publish(CHECK_AUTH_EVENT_NAME);
}

const isExpiredToken = () => {
	let accessToken = getAccessToken();
	if (!accessToken) {
		return true;
	}
	let decodedToken = jwtDecode(accessToken);
	
	if (!decodedToken) {
		return true;
	}
	let expirationDate = new Date(0);
	expirationDate.setUTCSeconds(decodedToken.exp);
	// console.log(`From token ${decodedToken.exp} and now ${Date.now()} and ${expirationDate} ${expirationDate < Date.now()}`)
	return expirationDate < Date.now();
}

export const getAccessToken = () => {
	return localStorage.getItem(TOKEN_ITEM_NAME);
}

export const login = async (loginRequest) => {
	// delete axios.defaults.headers.common["Authorization"];
	axios.defaults.headers.common["Access-Control-Allow-Credentials"] = true
	const url = `${USERS_API_BASE_URL}${SIGN_IN_URL}`;
	const request = {
		username: loginRequest.email,
		password: loginRequest.password
	}
	
	const response = await axios.post(url, request)
			.catch(error => {
				console.error(error);
				toast.error(
						"Ops! Something went wrong! Please try again later.");
			});
	localStorage.setItem(TOKEN_ITEM_NAME, response.data.accessToken);
	localStorage.setItem(REFRESH_TOKEN_ITEM_NAME, response.data.refreshToken);
	publish(CHECK_AUTH_EVENT_NAME)
}

// const refreshToken = async () => {
// 	// delete axios.defaults.headers.common["Authorization"];
// 	const url = `${USERS_API_BASE_URL}${REFRESH_TOKEN_URL}`;
// 	const request = {
// 		refreshToken: localStorage.getItem(REFRESH_TOKEN_ITEM_NAME)
// 	}
// 	const response = await axios.post(url, request)
// 			.catch(error => {
// 				console.error(error);
// 				toast.error(
// 						"Ops! Something went wrong! Please try again later.");
// 			});
// 	localStorage.setItem(TOKEN_ITEM_NAME, response.data.accessToken);
// 	localStorage.setItem(REFRESH_TOKEN_ITEM_NAME, response.data.refreshToken);
// 	return response.data.accessToken;
// }
