import {USERS_API_BASE_URL} from "../constants/UrlBasePathConst";
import axios from "axios";
import {toast} from "react-hot-toast";
import {publish} from "../events";
import {
	CHECK_AUTH_EVENT_NAME,
	REFRESH_TOKEN_ITEM_NAME,
	TOKEN_ITEM_NAME
} from "../constants";
import {getAccessToken, isAuthenticated} from "../providers/AuthProvider";
import {config} from "react-transition-group";

const REFRESH_TOKEN_URL = "/api/v1/auth/refresh-token";

const refreshToken = async () => {
	const url = `${USERS_API_BASE_URL}${REFRESH_TOKEN_URL}`;
	const request = {
		refreshToken: localStorage.getItem(REFRESH_TOKEN_ITEM_NAME)
	}
	const response = await axios.post(url, request)
			.catch(error => {
				console.error(error);
				toast.error(
						"Ops! Something went wrong! Please try again later.");
			});
	localStorage.setItem(TOKEN_ITEM_NAME, response.data.accessToken);
	localStorage.setItem(REFRESH_TOKEN_ITEM_NAME, response.data.refreshToken);
	publish(CHECK_AUTH_EVENT_NAME);
}

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(async (config) => {
			if(!isAuthenticated()) {
				await refreshToken();
			}
			config.headers['Authorization'] = createBearerToken();
			config.headers['Access-Control-Allow-Origin'] = true;
			config.headers['Content-Type'] = "application/json; charset=UTF-8";

			return config;
		},
		error => {
			console.log(error);
			return Promise.reject(error);
		});

axiosInstance.interceptors.response.use(
		response => response,
		async (error) => {
			console.log(error)
			if (error.response && error.response.status === 401) {
				try {
					await refreshToken();
					axiosInstance.defaults.headers.common['Authorization'] = createBearerToken();
					
					const originalRequest = error.config;
					originalRequest.headers.common["Authorization"] = createBearerToken();
					return axiosInstance(config);
				} catch (refreshError) {
					
					return Promise.reject(refreshError);
				}
			}
			
			return Promise.reject(error);
		});

const createBearerToken = () => {
	return `Bearer ${getAccessToken()}`;
}
