import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import classes from "./BorderLinearProgress.module.css";
import {defaultTheme} from "../../../theme/AppDefaultTheme";
import {ThemeProvider} from "@mui/material/styles";

export const BorderLinearProgress = (props) => {
	return (<ThemeProvider theme={defaultTheme}>
		<LinearProgress className={classes.progressBar} variant="determinate" value={props.value}/>
	</ThemeProvider>)
}
