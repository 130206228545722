import React from "react";
import styles from "./InputField.module.css"

export const InputField = (props) => {
	const customStyle = props.isDarkBackground
			? [styles.cardInput, styles.borderOnDarkBackground].join(' ')
			: styles.cardInput;
	
	return (
			<textarea
					id={props.id}
					rows="1"
					className={customStyle}
					placeholder={props.placeholder}
					value={props.value}
					onChange={props.onChangeCallback}
			/>
	)
}
