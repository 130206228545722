import TextField from "@mui/material/TextField";
import {useState} from "react";

export const ValidatedTextField = (props) => {
	
	const [value, setValue] = useState("");
	const [error, setError] = useState(false);
	const handleChange = e => {
		const newValue = e.target.value;
		const errorMessage = props.validator(newValue);
		setValue(newValue);
		setError(errorMessage);
		props.onChange(!errorMessage);
	};
	return (
			<TextField {...props}
					label={props.label}
					value={value}
					onChange={handleChange}
					error={!!error}
					helperText={error}
			/>
	);
};
