// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BorderLinearProgress_progressBar__c5QKW {
  height: 10px;
  border-radius: 5px;
  background-color: var(--main-border-color);
}
`, "",{"version":3,"sources":["webpack://./src/app/components/ui/progress/linear/BorderLinearProgress.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;EAClB,0CAA0C;AAC5C","sourcesContent":[".progressBar {\n  height: 10px;\n  border-radius: 5px;\n  background-color: var(--main-border-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBar": `BorderLinearProgress_progressBar__c5QKW`
};
export default ___CSS_LOADER_EXPORT___;
