import {v4 as uuid} from 'uuid';
import {DICTIONARY_API_BASE_URL} from "../constants/UrlBasePathConst";
import {toast} from "react-hot-toast";
import {DEFAULT_ERROR_MESSAGES} from "../constants/ErrorMesssageConstant";
import {axiosInstance} from "../interceptors/AxiousInstance";

const STUDY_SETS_URL = '/api/v1/study-sets'

export const getStudySetPage = async () => {
	const url = `${DICTIONARY_API_BASE_URL}${STUDY_SETS_URL}`;
	return (await axiosInstance.get(url)
			.catch(error => {
				console.error(error);
				toast.error(DEFAULT_ERROR_MESSAGES);
			}))
			.data;
}

export const createStudySet = async (studySet) => {
	const url = `${DICTIONARY_API_BASE_URL}${STUDY_SETS_URL}`;
	studySet.requestId = uuid();
	
	return (await axiosInstance.post(url, studySet))
			.data;
}

export const deleteStudySet = async (studySet) => {
	const url = `${DICTIONARY_API_BASE_URL}${STUDY_SETS_URL}/${studySet.id}`;
	return (await  axiosInstance.delete(url)).data;
}


