import React from "react";
import classes from "./PrimaryButton.module.css"

export const PrimaryButton = ({children, fullWidth, ...attributes}) => {
	
	return (
			<button
					className={fullWidth ? [classes.buttonPrimary, classes.fullWidth].join(' '): [classes.buttonPrimary].join(' ')}
					 {...attributes}>
				{children}
			</button>
	)
}
