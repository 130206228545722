import React from 'react';
import './Home.css';
import {Main} from "../main/Main";
import {Landing} from "../component/landing/Landing";
import {useAuth} from "../hooks/useAuth";

const Home = () => {
    
    const authenticated = useAuth();

    return (
        <div>
            {authenticated ? <Main/> : <Landing/>}
        </div>
    )

}

export default Home;
