export const nameValidator = value => {
	if (value.length < 3) return "Name must be at least 3 characters long";
	if (value.length > 20) return "Name must be less than 20 characters long";
	if (!/^[a-zA-Z ]+$/.test(value))
		return "Name must contain only letters and spaces";
	return false;
};
export const emailValidator = value => {
	if (!/^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/.test(value))
		return "Invalid email address";
	return false;
};

export const passwordValidator = value => {
	if (!/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(value))
		return "Invalid password. Password should contain minimum eight characters,"
				+ " at least one letter, one number and one special character";
	return false;
};
