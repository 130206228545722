// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ExaminationCard_commonSection__e\\+5ya {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ExaminationCard_examinationCard__OIdOA {
  max-width: var(--width-global);
  width: 80%;
  box-shadow: var(--main-header-font-color) 2px 2px 4px;
  border-radius: 10px;
  margin: 2.5rem;
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;
  background-color: var(--action-color-opposite);
}

.ExaminationCard_checkButton__xrA2R {
  width: 200px;
  height: 50px;
  background-color: var(--action-color);
  border: none;
  border-radius: 7px;
  color: var(--action-color-opposite);
  font-size: 18px;
  font-family: inherit;
  font-weight: 600;
  margin: 5px;
}

.ExaminationCard_checkButton__xrA2R:hover {
  background-color: var(--action-color-hover);
}

.ExaminationCard_checkButton__xrA2R:active {
  background-color: var(--action-color-action);
}

.ExaminationCard_definitionValue__-JsWc {
  font-family: var(--main-font-family);
  font-weight: bold;
  font-size: 48px;
}
`, "",{"version":3,"sources":["webpack://./src/component/examination/card/ExaminationCard.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,8BAA8B;EAC9B,UAAU;EACV,qDAAqD;EACrD,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,8CAA8C;AAChD;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,qCAAqC;EACrC,YAAY;EACZ,kBAAkB;EAClB,mCAAmC;EACnC,eAAe;EACf,oBAAoB;EACpB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,2CAA2C;AAC7C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;EACE,oCAAoC;EACpC,iBAAiB;EACjB,eAAe;AACjB","sourcesContent":[".commonSection {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n}\n\n.examinationCard {\n  max-width: var(--width-global);\n  width: 80%;\n  box-shadow: var(--main-header-font-color) 2px 2px 4px;\n  border-radius: 10px;\n  margin: 2.5rem;\n  padding: 2.5rem;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  gap: 2.5rem;\n  background-color: var(--action-color-opposite);\n}\n\n.checkButton {\n  width: 200px;\n  height: 50px;\n  background-color: var(--action-color);\n  border: none;\n  border-radius: 7px;\n  color: var(--action-color-opposite);\n  font-size: 18px;\n  font-family: inherit;\n  font-weight: 600;\n  margin: 5px;\n}\n\n.checkButton:hover {\n  background-color: var(--action-color-hover);\n}\n\n.checkButton:active {\n  background-color: var(--action-color-action);\n}\n\n.definitionValue {\n  font-family: var(--main-font-family);\n  font-weight: bold;\n  font-size: 48px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commonSection": `ExaminationCard_commonSection__e+5ya`,
	"examinationCard": `ExaminationCard_examinationCard__OIdOA`,
	"checkButton": `ExaminationCard_checkButton__xrA2R`,
	"definitionValue": `ExaminationCard_definitionValue__-JsWc`
};
export default ___CSS_LOADER_EXPORT___;
