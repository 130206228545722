import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";

export const Main = () => {
	const navigate = useNavigate();
	useEffect(() => {
		navigate(`/study-sets`)
	}, []);
	return (
			<div>
			</div>
	)
}
