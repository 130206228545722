// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InputField_cardInput__SS6OY {
  width: 100%;
  border: none;
  resize: none;
  font-size: 16px;
  font-family: var(--main-font-family);
  color: var(--main-text-font-color);
  vertical-align: text-bottom;
  border-bottom: 2px solid var(--main-background-color);
  padding: 0.8rem 0.3rem 0.1rem 0.3rem;
  background-color: transparent;
}

.InputField_cardInput__SS6OY::placeholder {
  font-size: 16px;
  font-family: var(--main-font-family);
  color: var(--main-inactive-text-color);
  vertical-align: bottom;
}

.InputField_cardInput__SS6OY:focus-visible {
  outline: none;
  border: none;
  border-bottom: 2px solid var(--action-color);
}

.InputField_borderOnDarkBackground__DZ7Z\\+ {
  border-bottom: 1px solid var(--main-inactive-text-color);;
}
`, "",{"version":3,"sources":["webpack://./src/component/ui/input_field/InputField.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,eAAe;EACf,oCAAoC;EACpC,kCAAkC;EAClC,2BAA2B;EAC3B,qDAAqD;EACrD,oCAAoC;EACpC,6BAA6B;AAC/B;;AAEA;EACE,eAAe;EACf,oCAAoC;EACpC,sCAAsC;EACtC,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,4CAA4C;AAC9C;;AAEA;EACE,wDAAwD;AAC1D","sourcesContent":[".cardInput {\n  width: 100%;\n  border: none;\n  resize: none;\n  font-size: 16px;\n  font-family: var(--main-font-family);\n  color: var(--main-text-font-color);\n  vertical-align: text-bottom;\n  border-bottom: 2px solid var(--main-background-color);\n  padding: 0.8rem 0.3rem 0.1rem 0.3rem;\n  background-color: transparent;\n}\n\n.cardInput::placeholder {\n  font-size: 16px;\n  font-family: var(--main-font-family);\n  color: var(--main-inactive-text-color);\n  vertical-align: bottom;\n}\n\n.cardInput:focus-visible {\n  outline: none;\n  border: none;\n  border-bottom: 2px solid var(--action-color);\n}\n\n.borderOnDarkBackground {\n  border-bottom: 1px solid var(--main-inactive-text-color);;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardInput": `InputField_cardInput__SS6OY`,
	"borderOnDarkBackground": `InputField_borderOnDarkBackground__DZ7Z+`
};
export default ___CSS_LOADER_EXPORT___;
