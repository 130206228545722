import React from "react";
import './Label.css'

export const Label = ({value, isUpperCase}) => {
  return (
      <label className={isUpperCase? "label label-uppercase": "label"}>
        {value}
      </label>
  )
}
