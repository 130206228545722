import AccountMenu from "../../../../common/AccountMenu";
import {Stack} from "@mui/material";
import React from "react";
import {PrimaryButton} from "../../ui/button/primary/PrimaryButton";
import {loginPath} from "../../../ApiConstant"
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../../hooks/useAuth";

export const Menu = () => {
	const authenticated = useAuth();
	const buttonContent = "Login";
	const navigate = useNavigate();
	const forwardToLoginPage = () => {
		navigate(loginPath)
	}
	
	return (
			<div>
				{authenticated ?
						<Stack>
							<AccountMenu/>
						</Stack> :
						<PrimaryButton onClick={forwardToLoginPage}>
							{buttonContent}
						</PrimaryButton>}
						</div>
					
					)
				}
