import React from 'react';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import {BrowserRouter as Router} from 'react-router-dom';
import Application from "./app/Application";
import {createRoot} from "react-dom/client";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <Router>
        <DevSupport ComponentPreviews={ComponentPreviews}
                    useInitialHook={useInitial}
        >
            <Application/>
        </DevSupport>
    </Router>,
);

registerServiceWorker();
