import React, {useEffect, useState} from 'react';
import styles from './StudySet.module.css'
import {StudyCard} from "../study_card/StudyCard";
import {
	addStudySetToStudyCard,
	deleteStudyCardFromStudySet,
	getStudySetById,
	updateStudySetById
} from "../../providers/CardService";
import {
	StudySetSkeleton
} from "../ui/skeleton/study_set_skeleton/StudySetSkeleton";
import {useParams} from "react-router-dom";
import {Label} from "../ui/label/Label";
import {InputField} from "../ui/input_field/InpitField";

export function StudySet() {
	const params = useParams();
	const [studySet, setStudySet] = useState(null);
	const [isLoading, setIsLoading] = useState(true)
	const [cards, setCards] = useState(null);
	const [trigger, setTrigger] = useState(0);
	const [reload, setReload] = useState(false);
	const [isMount, setIsMount] = useState(false);
	let delay;
	
	useEffect(() => {
		mountStudySetById(params.id)
	}, []);
	
	useEffect(() => {
		if (reload) {
			mountStudySetById(params.id)
					.then(() => setReload(false));
		}
	}, [reload])
	
	const mountStudySetById = (studySetId) => {
		return getStudySetById(studySetId)
				.then(resp => {
					setStudySet(resp);
					setIsLoading(false);
					setCards(resp.cards.sort((o1, o2) => o1.id - o2.id));
				});
	}
	
	const createEmptyCard = () => {
		return {
			id: undefined,
			expression: undefined,
			definitions: [{}]
		};
	}
	
	const checkIsEmptyExists = () => {
		return cards
				.some(card => card.id === undefined)
	}
	
	const addNewCard = () => {
		if (checkIsEmptyExists()) {
			setTrigger(trigger + 1);
		} else {
			setCards([...cards, createEmptyCard()]);
		}
	}
	
	const addCardToStudySet = (id) => {
		if (studySet.cards.find(item => id === item.id)) {
			return;
		}
		addStudySetToStudyCard(studySet.id, id)
				.then(() => getStudySetById(studySet.id)
						.then(resp => {
							setStudySet(resp);
							setCards(resp.cards);
							setIsLoading(false);
						}))
	}
	
	const deleteCardFromStudySet = (cardId) => {
		
		if (cardId == null) {
			setReload(true);
			return Promise.resolve();
		}
		
		return Promise.resolve(deleteStudyCardFromStudySet(studySet.id, cardId))
				.then(() => {
					setReload(true);
				});
	}
	
	useEffect(() => {
		if (isMount) {
			delay = setTimeout(() => {
				updateStudySetById(studySet)
			}, 3000)
			return () => clearTimeout(delay)
		} else {
			setIsMount(true)
		}
	}, [studySet]);
	
	return (
			<section>{
				isLoading ? <StudySetSkeleton/>
						: <ul className={styles.main}>
							<li className={[styles.studySetInfo,
								styles.studySetListItem].join(' ')}>
								<InputField
										isDarkBackground={true}
										id={"title"}
										value={studySet.title}
										onChangeCallback={e => setStudySet(
												{
													...studySet,
													title: e.target.value
												})}
								/>
								<Label value={"Title"} isUpperCase={true}/>
							</li>
							<li className={[styles.studySetInfo,
								styles.studySetListItem].join(' ')}>
								<InputField
										isDarkBackground={true}
										id={"description"}
										value={studySet.description}
										onChangeCallback={e => setStudySet(
												{
													...studySet,
													description: e.target.value
												})}
								/>
								<Label value={"Description"}
								       isUpperCase={true}/>
							</li>
							
							
							<div className={styles.cardSet}>
								{cards.map((card, index) =>
										<StudyCard key={index}
										           card={card}
										           trigger={trigger}
										           addCardToStudySet={addCardToStudySet}
										           deleteCardFromStudySet={deleteCardFromStudySet}
										           studiedLanguage={studySet.studiedLanguage}
										           nativeLanguage={studySet.nativeLanguage}
										/>)}
								<div className={styles.card}>
									<button type="button"
									        className={styles.addCard}
									        onClick={addNewCard}>
                          <span>
                            {"+ Add card"}
                          </span>
									</button>
								</div>
							</div>
							<div className={styles.right}>
								<button type="button"
								        className={styles.mainButton}>Create
								</button>
							</div>
						</ul>
			}
			</section>
	
	)
}
