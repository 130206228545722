import {v4 as uuid} from 'uuid';
import {axiosInstance} from "../interceptors/AxiousInstance";

const COURSE_API_BASE_URL= process.env.REACT_APP_COURSE_BASE_URL

export const getCourseByStudySetId = async (id) => {
	let url = `${COURSE_API_BASE_URL}/api/v1/courses`;
	let params = {
		"studySetId": id
	}
	return (await axiosInstance.get(url, {params}))
			.data
}

export const createCourseFromStudySet = async (studySetId) => {
	let createCourseRequest = {
		studySetId: studySetId,
		requestId: uuid()
	}
	let url = `${COURSE_API_BASE_URL}/api/v1/courses`;
	return (await axiosInstance.post(url, createCourseRequest))
			.data;
}
