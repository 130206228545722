// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-input-block-line {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  gap: 0.5rem;
}

.delete-definition-button {
  filter: invert(50%) sepia(92%) saturate(7098%) hue-rotate(238deg) brightness(84%) contrast(101%);
}

.delete-definition-button:hover {
  filter: invert(50%) sepia(92%) saturate(7098%) hue-rotate(238deg) brightness(84%) contrast(201%);
}

.delete-definition-button:active {
  filter: none;
}
`, "",{"version":3,"sources":["webpack://./src/component/definition/Definition.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,8BAA8B;EAC9B,WAAW;AACb;;AAEA;EACE,gGAAgG;AAClG;;AAEA;EACE,gGAAgG;AAClG;;AAEA;EACE,YAAY;AACd","sourcesContent":[".card-input-block-line {\n  display: flex;\n  flex-direction: row;\n  align-items: end;\n  justify-content: space-between;\n  gap: 0.5rem;\n}\n\n.delete-definition-button {\n  filter: invert(50%) sepia(92%) saturate(7098%) hue-rotate(238deg) brightness(84%) contrast(101%);\n}\n\n.delete-definition-button:hover {\n  filter: invert(50%) sepia(92%) saturate(7098%) hue-rotate(238deg) brightness(84%) contrast(201%);\n}\n\n.delete-definition-button:active {\n  filter: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
