import React, {useEffect, useState} from "react";
import classes from "./StudySetList.module.css"
import {createStudySet, getStudySetPage} from "../../providers/StudySetService";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {getLanguages} from "../../providers/LanguageService";
import {StudySetCard} from "./study_set_card/StudySetCard";
import {
	PrimaryButton
} from "../../app/components/ui/button/primary/PrimaryButton";
import {subscribe, unsubscribe} from "../../events";
import {UPDATE_STUDY_SET_LIST_EVENT} from "../../constants";

export const StudySetList = () => {
	const [studySetList, setStudySetList] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const router = useNavigate();
	const [open, setOpen] = useState(false);
	const [languages, setLanguages] = useState(null);
	const [reload, setReload] = useState(false)
	
	useEffect(() => {
		if (reload) {
			setIsLoading(true);
			getStudySetPage()
					.then(data => {
						setStudySetList(data.content);
					})
					.then(() => setIsLoading(false))
					.then(() => setReload(false))
		}
	}, [reload]);

	useEffect(() => {
		const fetchData = async () => {
			await getStudySetPage()
					.then(data => {
						setStudySetList(data.content);
					})
			await getLanguages()
					.then(data => {
						setLanguages(data)
					})

			setIsLoading(false);
		}

		fetchData();
	}, []);
	
	useEffect(() => {
		const updateStudySetHandler = () => {
			setReload(true);
		}
		subscribe(UPDATE_STUDY_SET_LIST_EVENT, () => updateStudySetHandler())
		return () => unsubscribe(UPDATE_STUDY_SET_LIST_EVENT, updateStudySetHandler);
	}, []);
	
	const addNewStudySet = () => {
		setOpen(true);
	}
	
	return (
			<section className={classes.main}>
				{isLoading ?
						<div></div>
						:
						<div className={classes.listContent}>
							<CreateNewStudySetModal
									open={open}
									setOpen={setOpen}
									languages={languages}
									setReload={setReload}
							/>
							<PrimaryButton onClick={addNewStudySet}>
								+ Add study set
							</PrimaryButton>
							<ul className={[classes.list, classes.content].join(
									' ')}>
								{studySetList.map(item => {
									return <li key={item.id+1}
									           className={classes.listItem}>
										<StudySetCard studySet={item}/>
									</li>
								})}
							
							</ul>
						</div>
				}
			</section>
	)
}

function CreateNewStudySetModal({open, setOpen, languages, setReload}) {
	
	const [nativeLanguageId, setNativeLanguageId] = useState('')
	const [studiedLanguageId, setStudiedLanguageId] = useState('')
	const [title, setTitle] = useState('')
	const [description, setDescription] = useState('')
	
	const handleClose = () => {
		setOpen(false);
	};
	
	const handleChangeNativeLanguage = (event) => {
		setNativeLanguageId(event.target.value);
	}
	
	const handleChangeStudiedLanguage = (event) => {
		setStudiedLanguageId(event.target.value);
	}
	
	const handleChangeTitle = (event) => {
		setTitle(event.target.value);
	}
	
	const handleChangeDescription = (event) => {
		setDescription(event.target.value);
	}
	
	const handleCreate = async () => {
		let studySet = {
			title: title,
			description: description,
			nativeLanguageId: nativeLanguageId,
			studiedLanguageId: studiedLanguageId
		}
		
		await createStudySet(studySet);
		setOpen(false);
		setReload(true);
	}
	
	return (
			<div>
				<Dialog open={open} onClose={handleClose}>
					<DialogTitle>Create new study set</DialogTitle>
					<DialogContent>
						<DialogContentText>
							Please fill all required fields
						</DialogContentText>
						<TextField
								required={true}
								autoFocus
								margin="dense"
								id="title"
								label="Title"
								type="text"
								fullWidth
								variant="standard"
								onChange={handleChangeTitle}
						/>
						<TextField
								required={true}
								autoFocus
								margin="dense"
								id="description"
								label="Description"
								type="text"
								fullWidth
								variant="standard"
								onChange={handleChangeDescription}
						/>
						<FormControl variant="standard"
						             sx={{m: 1, minWidth: 200}}>
							<InputLabel id="select-native-language-label">Native
								Language</InputLabel>
							<Select
									required={true}
									labelId="select-native-language-label"
									id="select-native-language"
									value={nativeLanguageId}
									onChange={handleChangeNativeLanguage}
									label="Native Language"
							>
								<MenuItem value="" key={0}>
									<em>None</em>
								</MenuItem>
								{languages.map(item => <MenuItem
										key={item.id} value={item.id}>{item.value}</MenuItem>)}
							</Select>
						</FormControl>
						<FormControl variant="standard"
						             sx={{m: 1, minWidth: 200}}>
							<InputLabel id="demo-simple-select-standard-label">Studied
								Language</InputLabel>
							<Select
									required={true}
									labelId="demo-simple-select-standard-label"
									id="demo-simple-select-standard"
									value={studiedLanguageId}
									onChange={handleChangeStudiedLanguage}
									label="StudiedLanguage"
							>
								<MenuItem value="" key={1}>
									<em>None</em>
								</MenuItem>
								{languages.map(item => <MenuItem
										key={item.id} value={item.id}>{item.value}</MenuItem>)}
							</Select>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={handleCreate}>Create</Button>
					</DialogActions>
				</Dialog>
			</div>
	);
}

