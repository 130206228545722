import logo from "../../../../img/logo_transparent_background_icon.svg";
import React from "react";
import classes from "./CommonHeader.module.css"
import {Menu} from "../../common/menu/Menu";


export const CommonHeader = () => {
	return (
			<nav className={classes.sectionNavigation}>
				<a href="/" className={classes.sectionNavigationLogo}>
					<img
							className={classes.sectionNavigationLogoImg}
							src={logo}
							alt="BecomePolyglot logo"/>
					<p className={classes.sectionNavigationLogoName}>
						<span>Become</span>
						<span>Polyglot</span></p>
				</a>
				<Menu/>
			</nav>
	)
}
