// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CommonHeader_sectionNavigation__AfQwY {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 2rem;
}

.CommonHeader_sectionNavigationLogo__sXR\\+3 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: .5rem;
}

.CommonHeader_sectionNavigationLogoImg__M0qL5 {
  width: auto;
  height: 4rem;
}

.CommonHeader_sectionNavigationLogoName__el2YJ {
  font-weight: 700;
  color: var(--main-content-color);
  font-size: 1.25rem;
  line-height: 1.75rem;
}

@media (min-width: 640px) {
  .CommonHeader_sectionNavigation__AfQwY {
    padding-left: 4rem;
    padding-right: 4rem;
  }

}

@media (min-width: 768px) {

  .CommonHeader_sectionNavigationLogoName__el2YJ {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 1024px) {

  .CommonHeader_sectionNavigationLogo__sXR\\+3 {
    flex: 1 1;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/header/common_header/CommonHeader.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gCAAgC;EAChC,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE;IACE,kBAAkB;IAClB,mBAAmB;EACrB;;AAEF;;AAEA;;EAEE;IACE,iBAAiB;IACjB,iBAAiB;EACnB;AACF;;AAEA;;EAEE;IACE,SAAW;EACb;AACF","sourcesContent":[".sectionNavigation {\n  position: relative;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 1.5rem 2rem;\n}\n\n.sectionNavigationLogo {\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  column-gap: .5rem;\n}\n\n.sectionNavigationLogoImg {\n  width: auto;\n  height: 4rem;\n}\n\n.sectionNavigationLogoName {\n  font-weight: 700;\n  color: var(--main-content-color);\n  font-size: 1.25rem;\n  line-height: 1.75rem;\n}\n\n@media (min-width: 640px) {\n  .sectionNavigation {\n    padding-left: 4rem;\n    padding-right: 4rem;\n  }\n\n}\n\n@media (min-width: 768px) {\n\n  .sectionNavigationLogoName {\n    font-size: 1.5rem;\n    line-height: 2rem;\n  }\n}\n\n@media (min-width: 1024px) {\n\n  .sectionNavigationLogo {\n    flex: 1 1 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionNavigation": `CommonHeader_sectionNavigation__AfQwY`,
	"sectionNavigationLogo": `CommonHeader_sectionNavigationLogo__sXR+3`,
	"sectionNavigationLogoImg": `CommonHeader_sectionNavigationLogoImg__M0qL5`,
	"sectionNavigationLogoName": `CommonHeader_sectionNavigationLogoName__el2YJ`
};
export default ___CSS_LOADER_EXPORT___;
